interface FormResponse {
  success: boolean
  redirect?: string
  formId?: string
  message?: string
  log?: string
}

const repatchaField = "g-recaptcha-response";
const feedbackBoxQuery = "[data-js-form-feedback]";
const feedbackErrorBox = "[data-js-form-feedback-error]";

document.querySelectorAll("[data-js-form]").forEach(jsform => {
  if (jsform instanceof HTMLFormElement) {

    const submitButtons = jsform.querySelectorAll("button[type=submit], input[type=submit]");
    const submitButton = submitButtons ? submitButtons[0] : undefined
    if (!submitButton) {
      console.warn(`Form missing submit button`);
      return null;
    }

    const feedbackBox = jsform.querySelector(feedbackBoxQuery)
    if (!feedbackBox) {
      console.warn(`Form missing submission feedback box`);
    }

    const errorFeedbackBox = jsform.querySelector(feedbackErrorBox)
    if (!errorFeedbackBox) {
      console.warn(`Form missing submission error feedback box`);
    }

    const toggleSubmitButton = (disabled: boolean) => {
      if (submitButton instanceof HTMLButtonElement || submitButton instanceof HTMLInputElement) {
        submitButton.disabled = disabled;
      }
    }

    const validateForm = () => {
      let valid = true;
      const invalidElements: HTMLElement[] = []

      const recaptchaField = jsform.querySelector(`#${repatchaField}`) as HTMLTextAreaElement
      const recaptchaLabel = jsform.querySelector(`.input-recaptcha label`)
      if (recaptchaField) {
        if (!recaptchaField.value || (recaptchaField.value && recaptchaField.value?.length <= 0)) {
          valid = false;
          if(recaptchaLabel) {
            recaptchaLabel.classList.remove("hidden")
          }
        }
        else {
          if(recaptchaLabel) {
            recaptchaLabel.classList.add("hidden")
          }
        }
      }

      // Validate fieldsets with required checkboxes since browser validation doesnt kick in
      const requiredFieldsets = jsform.querySelectorAll("[data-js-checkboxlist-input][required]") as NodeListOf<HTMLFieldSetElement>;
      if (requiredFieldsets) {
        requiredFieldsets.forEach((fieldset) => {
          const haschecked = fieldset.querySelectorAll("input:checked")
          if (!haschecked || (haschecked && haschecked.length === 0)) {
            valid = false;
            fieldset.classList.add("invalid")
            invalidElements.push(fieldset)
            fieldset.querySelectorAll("input").forEach((input) => {
              input.classList.add("invalid")
            })
          }
          else {
            fieldset.classList.remove("invalid")
          }
        })
      }

      //Scroll first invalid element into view
      if (invalidElements && invalidElements.length > 0) {
        invalidElements[0].scrollIntoView()
      }

      return valid
    }

    const submitData = () => {

      if (!!feedbackBox) {
        feedbackBox.classList.add("hidden")
      }

      if (!!errorFeedbackBox) {
        errorFeedbackBox.classList.add("hidden")
      }

      if (!validateForm()) {
        if (!!errorFeedbackBox) {
          errorFeedbackBox.classList.remove("hidden")
        }
        return false;
      }

      toggleSubmitButton(true)

      const formData = new FormData(jsform);

      try {
        fetch(`${window.location.origin}/formpost/json`, {
          method: "POST",
          body: formData,
        })
          .then((res: Response) => res.json() as Promise<FormResponse>)
          .then((data: FormResponse) => {
            if (data.success) {
              if (data.redirect && data.redirect.length > 0) {
                window.location.href = data.redirect
              }
              else if (!!feedbackBox) {
                feedbackBox.classList.remove("hidden")
              }
            }
            else if (!!errorFeedbackBox) {
              errorFeedbackBox.classList.remove("hidden")
              if (data.message) {
                console.warn(data.message)
              }
            }
            toggleSubmitButton(false)
          }).catch(err => {
            console.error(err)
            toggleSubmitButton(false)
          })
      } catch (e) {
        console.error(e);
        toggleSubmitButton(false)
      }

      return false;
    }

    jsform.addEventListener("submit", (event) => {
      event.preventDefault();
      event.stopPropagation();
      submitData();
    });

    const selectDropdowns = jsform.querySelectorAll("[data-js-dropdown-input]");
    if (selectDropdowns) {
      selectDropdowns.forEach((select) => {
        select.addEventListener("change", (event: Event) => {
          const selectElement = event.target as HTMLSelectElement
          if (selectElement) {
            selectElement.blur()
          }
        })
      })
    }
  }
  else {
    console.warn("Element is not of type form element");
  }

})